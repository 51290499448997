<template>
  <section>
    <v-row>
      <v-col cols="12" xl="8" lg="8" md="8" sm="10" xs="12">
        <p class="text-h5 secondary--text my-0">Subasta Interna</p>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <p class="text-h7 primary--text my-0">Fecha Incio : 09/11/2022</p>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12">
        <p class="text-h7 primary--text my-0">Fecha Fin : 09/11/2022</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <p class="mb-0">Salas disponible</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="4" sm="6" xs="12">
        <v-list nav dense>
          <v-list-item-group color="bgWhite">
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  @click="
                    seleccionar(1);
                    calcularTiempo();
                  "
                  :color="tabIndex === 1 ? 'secondary' : 'bgWhite'"
                  >Vehiculos Pesados</v-btn
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  @click="
                    seleccionar(2);
                    calcularTiempo();
                  "
                  :color="tabIndex === 2 ? 'secondary' : 'bgWhite'"
                  >Vehiculos Particulares</v-btn
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" xl="10" lg="9" md="8" sm="12" xs="12" v-if="tabSelect"
        ><v-card flat>
          <v-card-text>
            <v-row>
              <v-col>
                <p class="text-center text-h5 primary--text my-0">
                  {{ tabSelect.titulo }}
                </p>
                <p class="text-center text-h5 secondary--text my-4">
                  Tiempo restante:
                  <v-chip color="secondary" text-color="white"
                    >{{ countdown }}
                  </v-chip>
                </p>
                <v-row justify="center">
                  <v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12">
                    <v-chip class="ma-2" color="btnRed" text-color="white">
                      Mejor Oferta
                    </v-chip>
                    <v-chip class="ma-2" color="blueMinsal" text-color="white">
                      Tu Oferta
                    </v-chip>
                  </v-col>
                </v-row>
                <p class="text-center text-h2 primary--text my-2">
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(tabSelect.oferta)
                  }}
                  USD
                </p>
                <v-row justify="center" class="mt-5">
                  <v-col cols="12" xl="2" lg="3" md="3" sm="4" xs="6">
                    <v-text-field
                      label="Cantidad a ofertar"
                      v-model="oferta"
                      outlined
                      width="50px"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="1"
                    lg="2"
                    md="3"
                    sm="4"
                    xs="6"
                    class="pt-5"
                  >
                    <v-btn color="secondary">Ofertar</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn class="ma-7" outlined color="btnRed">Retirarse</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-btn
      outlined
      color="primary"
      @click="$router.push('/proceso-compra/ufi-aprobar-solicitud')"
      class="mt-15"
    >
      Volver
    </v-btn>
  </section>
</template>

<script>
import * as moment from "moment";
export default {
  name: "EtapaActualComponent",
  components: {},
  data: () => ({
    tabIndex: null,
    tabSelect: null,
    oferta: null,
    items: [
      {
        tipo: 1,
        titulo: "14111500 Motor de furgon",
        oferta: 3500,
        seconds: 1000,
      },
      {
        tipo: 2,
        titulo: "14111500 Vehiculo particular",
        oferta: 5700,
        seconds: 10246,
      },
    ],
    //Cronometro
    countdown: null,
    expires_in: null,
    interval: null,
    seconds: null,
  }),

  computed: {},

  methods: {
    seleccionar(option) {
      this.tabIndex = option;
      this.tabSelect = this.items.find((item) => {
        return item.tipo === option;
      });
      this.seconds = this.tabSelect.seconds;
    },
    //Metodo para calcular el tiempo restante
    calcularTiempo() {
      this.countdown = moment.utc(this.seconds).format("HH:mm:ss");
      this.expires_in = this.seconds;
      clearInterval(this.interval);
      this._setInterval();
    },
    _setInterval: function () {
      this.interval = setInterval(() => {
        if (this.expires_in === 0) {
          clearInterval(this.interval);
        } else {
          this.expires_in -= 1;
          this.countdown = moment
            .utc(this.expires_in * 1000)
            .subtract(1, "seconds")
            .format("HH:mm:ss");
        }
      }, 1000);
    },
  },
  async created() {},
};
</script>

<style>
.foo {
  position: absolute;
  top: 400px;
}
</style>
