import { render, staticRenderFns } from "./ListadoDocumentosProcesoComponent.vue?vue&type=template&id=4c821372&scoped=true&"
import script from "./ListadoDocumentosProcesoComponent.vue?vue&type=script&lang=js&"
export * from "./ListadoDocumentosProcesoComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c821372",
  null
  
)

export default component.exports