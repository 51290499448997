<template>
  <div>
    <p class="text-h6 secondary--text" color="secondary">
      Forma de contratación: Subasta inversa
    </p>
    <v-data-table :headers="headers" :items="etapas" hide-default-footer>
      <template v-slot:[`footer`]>
        <AppPaginationComponent
          :totalElements="etapas.length"
        ></AppPaginationComponent>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AppPaginationComponent from "../../../../components/AppPaginationComponent.vue";
export default {
  name: "ListadoEtapasProcesoComponent",
  components: {
    AppPaginationComponent,
  },
  data() {
    return {
      headers: [
        {
          text: "Etapas",
          align: "start",
          value: "nombre",
        },
        {
          text: "Fecha de incio",
          align: "center",
          value: "fechaInicio",
        },
        {
          text: "Fecha de fin",
          align: "center",
          value: "fechaFin",
        },
      ],
      etapas: [
        {
          id: 1,
          nombre: "Bases del proceso",
          fechaInicio: "29/04/2022 00:00",
          fechaFin: "02/05/2022 23:59",
        },
        {
          id: 2,
          nombre: "Consultas",
          fechaInicio: "03/05/2022 08:00",
          fechaFin: "05/05/2022 16:00",
        },
        {
          id: 3,
          nombre: "Aclaraciones y enmiendas",
          fechaInicio: "11/05/2022 08:00",
          fechaFin: "13/05/2022 16:00",
        },
        {
          id: 4,
          nombre: "Subasta inversa",
          fechaInicio: "18/05/2022 09:30",
          fechaFin: "18/05/2022 10:30",
        },
        {
          id: 5,
          nombre: "Resultados",
          fechaInicio: "23/05/2022 08:00",
          fechaFin: "27/05/2022 16:00",
        },
        {
          id: 6,
          nombre: "Contratación",
          fechaInicio: null,
          fechaFin: null,
        },
        {
          id: 7,
          nombre: "Cierre",
          fechaInicio: null,
          fechaFin: null,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
