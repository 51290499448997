<template>
  <div>
    <v-row>
      <v-col cols="3">
        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Forma de contratación</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.formaContratacion }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Contacto</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.contacto }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Fuente de financiamiento</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.fuenteFinanciamiento }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Fecha de publicación</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.fecha }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Período de inscripción</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.fechaInscripcion }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Código</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.codigo }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Estado</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.estado }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-col>
      <v-col cols="7">
        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="black--text"
                >Información adicional</v-list-item-subtitle
              >
              <p class="mt-2">
                {{ info.informacionAdicional }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TabGeneralComponent",
  data() {
    return {
      info: {
        formaContratacion: "Subasta inversa",
        contacto: "Karen Jeannete",
        fuenteFinanciamiento: "Fondos propios",
        fecha: "27/04/2022",
        fechaInscripcion: "27/04/2022 -29/04/2022",
        codigo: "P001-2022",
        estado: "En proceso",
        informacionAdicional:
          "Todo interesado en participar podrá obtener los documentos de solicitud de forma gratuita descargando directamente aquí. Es requisito indispensable que las empresas que desean participar en el proceso registren sus datos generales tal y como se solicitan dentro de dicho sitio web.",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__subtitle {
  font-size: 12px;
}
</style>
