<template>
  <div>
    <v-data-table :headers="headers" :items="documentos" hide-default-footer>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2" icon small v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Ver detalles</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer`]>
        <AppPaginationComponent
          :totalElements="documentos.length"
        ></AppPaginationComponent>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AppPaginationComponent from "../../../../components/AppPaginationComponent.vue";
export default {
  name: "ListadoDocumentosProcesoComponent",
  components: {
    AppPaginationComponent,
  },
  data() {
    return {
      headers: [
        {
          text: "Tipo de documento",
          align: "start",
          value: "nombre",
        },
        {
          text: "Fecha de última modificación",
          align: "center",
          value: "fechaModificacion",
        },
        {
          text: "Versiones",
          align: "center",
          value: "acciones",
        },
      ],
      documentos: [
        {
          id: 1,
          nombre: "Documentos de solicitud",
          fechaModificacion: "25/12/2021",
        },
        {
          id: 2,
          nombre: "Solicitud",
          fechaModificacion: "26/12/2021",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
