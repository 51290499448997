<template>
  <div>
    <p class="mt-10">Salas que conforman la subasta</p>
    <v-row>
      <v-col cols="8">
        <v-expansion-panels accordion multiple v-model="panel">
          <v-expansion-panel v-for="sala in salas" :key="sala.id">
            <v-expansion-panel-header class="white--text" color="#404a7a">{{
              sala.nombre
            }}</v-expansion-panel-header>

            <v-expansion-panel-content
              class="mb-n6"
              v-for="item in sala.elementos"
              :key="item.id"
            >
              <p>{{ item.nombre }}</p>
              <v-row>
                <v-col>
                  <span class="subtitle-label">Fecha y hora de inicio</span>
                  <p>{{ item.fechaInicio }} {{ item.horaInicio }}</p>
                </v-col>
                <v-col>
                  <span class="subtitle-label">Fecha y hora de fin</span>
                  <p>{{ item.fechaFin }} {{ item.horaFin }}</p>
                </v-col>
                <v-col>
                  <span class="subtitle-label">Monto inicial</span>
                  <p>{{ item.montoInicial | aMoneda }}</p>
                </v-col>
                <v-col>
                  <span class="subtitle-label">Cantidad</span>
                  <p>{{ item.cantidad }}</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TabProgramacionComponent",
  data() {
    return {
      panel: [0],
      salas: [
        {
          id: 1,
          nombre: "Vehículos",
          elementos: [
            {
              id: 1,
              nombre: "Motor de vehículo particular 1.5L",
              fechaInicio: "06/09/2022",
              horaInicio: "8:11 AM",
              fechaFin: "10/09/200",
              horaFin: "9:00 AM",
              montoInicial: 2500,
              cantidad: 5,
            },
            {
              id: 2,
              nombre: "Motor de vehículo particular 2.6L",
              fechaInicio: "08/09/2022",
              horaInicio: "8:30 AM",
              fechaFin: "10/09/200",
              horaFin: "9:00 AM",
              montoInicial: 2000,
              cantidad: 3,
            },
          ],
        },
        {
          id: 2,
          nombre: "Vehículos particulares",
          elementos: [
            {
              id: 1,
              nombre: "Motor de vehículo particular 1.6L",
              fechaInicio: "09/09/2022",
              horaInicio: "8:00 AM",
              fechaFin: "10/09/200",
              horaFin: "9:00 AM",
              montoInicial: 3500,
              cantidad: 5,
            },
            {
              id: 2,
              nombre: "Motor de vehículo particular 1.5L",
              fechaInicio: "09/09/2022",
              horaInicio: "8:30 AM",
              fechaFin: "10/09/200",
              horaFin: "9:00 AM",
              montoInicial: 3000,
              cantidad: 5,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .v-expansion-panel-content__wrap {
    margin-top: 22px;
    margin-bottom: 32px;
  }

  .v-expansion-panel-header__icon i {
    color: white !important;
  }
}

.subtitle-label {
  font-size: 12px;
}
</style>
