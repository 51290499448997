<template>
  <section>
    <v-row>
      <v-col cols="1">
        <img
          :src="require(`@/assets/img/logo_512_negro.png`)"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col>
        <p class="text-h6 secondary--text my-0">
          Suministro de papeleria y utiles de oficina
        </p>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      color="primary-text"
      class="mt-5"
      background-color="bgMinsal"
      show-arrows
    >
      <v-tab v-for="item in items" :key="item.tab">
        <v-icon small class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="slide-y-transition"
        reverse-transition="fade-transition"
        v-for="item in items"
        :key="item.tab"
      >
        <v-card flat color="bgMinsal">
          <component :is="item.content" class="pa-7"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import EtapaActualComponent from "./components/EtapaActualComponent.vue";
import ListadoDocumentosProcesoComponent from "./components/ListadoDocumentosProcesoComponent.vue";
import ListadoEtapasProcesoComponent from "./components/ListadoEtapasProcesoComponent.vue";
import TabGeneralComponent from "./components/TabGeneralComponent.vue";
import TabProgramacionComponent from "./components/TabProgramacionComponent.vue";

export default {
  name: "subastaInversaView",
  components: {
    TabGeneralComponent,
    TabProgramacionComponent,
    ListadoDocumentosProcesoComponent,
    ListadoEtapasProcesoComponent,
    EtapaActualComponent,
  },
  data: () => ({
    tab: null,
    items: [],
  }),
  created() {
    this.items = [
      {
        tab: "Etapa Actual",
        content: "EtapaActualComponent",
        icon: "mdi-cash-multiple",
      },
      {
        tab: "General",
        content: "TabGeneralComponent",
        icon: "mdi-clipboard-text",
      },
      {
        tab: "Programacion",
        content: "TabProgramacionComponent",
        icon: "mdi-format-list-bulleted",
      },
      {
        tab: "Documentos del proceso",
        content: "ListadoDocumentosProcesoComponent",
        icon: "mdi-book-open-variant",
      },
      {
        tab: "Etapa del proceso",
        content: "ListadoEtapasProcesoComponent",
        icon: "mdi-format-list-numbered",
      },
    ];
  },
};
</script>

<style></style>
